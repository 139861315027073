import { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import randomstring from "randomstring";
import { isDesktop } from "react-device-detect";
import auth from "basic-auth";

import { Modal, ModalBody, ModalContent } from "@nextui-org/react";
import { getSessionToken, getUserToken } from "@/api/demo";
import Alert from "@/components/Alert";
import AlertErrorContent from "@/components/Alert/ErrorContent";
import { alertDrawer } from "@/components/layout";
import { ENVIRONMENT } from "@/utils/environment";

import {
  AVATAR_DEMO_DATA,
  SessionStrorageKey,
  INITAL_MODAL_DIALOG,
} from "./const";

import {
  chatToken,
  demoModalDialog,
  isHighDemand,
  selectedAvatar,
  waitingList,
  isShowBythenWatermark,
} from "./signal";
import DemoAvatar from "./avatar";
import Head from "next/head";

function DemoSlugPage({
  avatar,
  ogImage,
  ogUrl,
  metaTitle,
  metaDescription,
  isShowWatermark,
  favicon,
}) {
  isShowBythenWatermark.value = isShowWatermark;
  const onRequestChatToken = useCallback(async () => {
    if (isDesktop) return;
    let randomString = Cookies.get(SessionStrorageKey.CHAT_RANDOM_DEVICE);

    if (!randomString) {
      randomString = randomstring.generate({
        length: 32,
      });

      Cookies.set(SessionStrorageKey.CHAT_RANDOM_DEVICE, randomString, {
        expires: 1,
        domain: ".bythen.ai",
        path: "/",
      });
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_id: randomString,
      }),
    };

    try {
      const resp = await fetch(
        `${ENVIRONMENT.API_STREAM}/ms/discovery/v1/hosts/request_access`,
        requestOptions
      );

      const respJson = await resp.json();

      // If Waiting List
      if (!respJson.token.length || respJson.queue > 0) {
        if (!waitingList.value) isHighDemand.value = true;
        return;
      }

      demoModalDialog.value = { ...INITAL_MODAL_DIALOG };

      //jika engga, set token
      waitingList.value = false;
      chatToken.value = respJson.token;
    } catch (error) {
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={error.message} />,
        },
      ];
    }
  }, []);

  const onRequestSessionToken = useCallback(async () => {
    try {
      const { data: sessionToken } = await getSessionToken();

      if (!sessionToken?.token) throw new Error("Session token not generated");

      selectedAvatar.value = {
        ...avatar,
        ...{ token: sessionToken.token, refetch: true },
      };

      if (!isDesktop) {
        onRequestChatToken();
      }

      demoModalDialog.value.show = false;
    } catch (error) {
      console.log("🚀 ~ onRequestSessionToken ~ error:", error);
      Cookies.remove("DEMO_TOKEN");
      // const errorInfo = JSON.parse(error.message);
      return (alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={error.message} />,
        },
      ]);
    }
  }, [avatar, onRequestChatToken]);

  const fetchUserToken = useCallback(async () => {
    try {
      const { data: userToken } = await getUserToken();

      Cookies.set("DEMO_TOKEN", userToken.token, {
        expires: 365 * 5,
      });

      onRequestSessionToken();
    } catch (e) {
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={e.message} />,
        },
      ];
    }
  }, [onRequestSessionToken]);

  useEffect(() => {
    if (waitingList.value && !isDesktop) {
      const intervalId = setInterval(() => {
        onRequestChatToken();
      }, 2000);

      if (chatToken.value) clearInterval(intervalId);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRequestChatToken, waitingList.value]);

  useEffect(() => {
    if (!Cookies.get("DEMO_TOKEN")) {
      fetchUserToken();
    } else {
      onRequestSessionToken();
    }
  }, []);

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="robots" content="index,follow" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta itemProp="name" content={metaTitle} />
        <meta itemProp="description" content={metaDescription} />
        <meta itemProp="image" content={ogImage} />

        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="bythen" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={metaTitle} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:image:type" content="image/png" />
        <link rel="shortcut icon" href={favicon} />
      </Head>

      {alertDrawer.value.length ? <Alert /> : null}

      {selectedAvatar.value.token && <DemoAvatar />}

      <Modal
        isOpen={demoModalDialog.value.show}
        placement="center"
        isDismissable={demoModalDialog.value.backgroundClose}
        onClose={() => demoModalDialog.value.onClose()}
        scrollBehavior={"inside"}
        backdrop={demoModalDialog.value.backdrop}
        hideCloseButton={demoModalDialog.value.hideCloseButton}
        classNames={{
          wrapper: "w-full z-[999]",
          backdrop: "backdrop-brightness-50 z-[999]",
          base: `overflow-y-visible max-h-[70vh] lg:max-h-[80vh] w-[90%] max-w-[542px] m-0 lg:mx-6 sm:my-0 rounded-3xl" ${demoModalDialog.value.baseClass} z-[999]`,
          body: `px-[28px] py-[84px] pb-[28px] rounded-3xl bg-pampas justify-center items-center ${demoModalDialog.value.bodyClass}`,
          closeButton: `hover:bg-white/5 active:bg-white/10 [&>svg]:w-[24px] [&>svg]:h-[24px] p-3 ${demoModalDialog.value.closeButtonClass}`,
        }}
      >
        <ModalContent>
          <ModalBody>{demoModalDialog.value.body}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DemoSlugPage;

export const getServerSideProps = async ({ query, req, res, resolvedUrl }) => {
  let avatar = null;
  let ogImage = `${ENVIRONMENT.ASSETS_URL}/demo/og-image.png`;
  let ogUrl = `${ENVIRONMENT.BASE_URL}${resolvedUrl}`;
  let metaTitle = "bythen - Talk to bytes";
  let metaDescription =
    "Interact and experience bythen’s byte - 3D Advanced PFP";
  let isShowWatermark = false;
  let favicon = "/favicon.ico";

  const avatarDetail = AVATAR_DEMO_DATA.find(
    (ava) => ava.name.toLowerCase() === query.slug
  );

  if (avatarDetail) {
    avatar = {
      avatarId: avatarDetail.avatarId,
      name: avatarDetail.name,
      image: avatarDetail.image,
      messageID: avatarDetail.messageID,
      loaderBG: avatarDetail.loaderBG,
      isDisabled: avatarDetail.isDisabled,
      isPrivate: avatarDetail.isPrivate,
    };

    if (avatarDetail.isPrivate) {
      const credentials = auth(req);

      let expectedUsername = "bythen";
      let expectedPassword = "bythen2100";

      if (avatarDetail.credentials) {
        const { username, password } = avatarDetail.credentials;
        expectedUsername = username;
        expectedPassword = password;
      }

      if (
        !credentials ||
        credentials.name !== expectedUsername ||
        credentials.pass !== expectedPassword
      ) {
        res.setHeader("WWW-Authenticate", 'Basic realm="Secure Area"');
        res.statusCode = 401;
        res.end("Unauthorized");
        return { props: {} };
      }
    }
  } else {
    return {
      redirect: {
        permanent: false,
        destination: `${ENVIRONMENT.BASE_URL}/demo`,
      },
    };
  }

  if (req.headers.host.includes("donpengu.ai") || query.slug === "don_pengu") {
    ogImage = `${ENVIRONMENT.ASSETS_URL}/demo/ad-hoc/donpengu-og.png`;
    ogUrl = `https://donpengu.ai`;
    metaTitle = "Talk with Don Pengu!";
    metaDescription =
      "Experience and Discover How Don Pengu Will Make America Great Again!";
    isShowWatermark = true;
    favicon = avatarDetail.image;
  }

  return {
    props: {
      avatar,
      ogImage,
      ogUrl,
      metaTitle,
      metaDescription,
      isShowWatermark,
      favicon,
    },
  };
};
